import { useContext, useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import { SetSession } from "./functions/Session";
import Cookies from "js-cookie";
import Winner from "./pages/Winner";
import Nav from "./components/Nav";
import Add from "./pages/Add";
import "./App.css";
import NavManage from "./side/NavContext";
import PlayerInfo from "./pages/PlayerInfo";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Error from "./components/Error";
import AddGame from "./pages/AddGame";
import CustomGame from "./pages/CustomGame";
import CustomGameLog from "./pages/CustomGameLog";
import {
  IsThereASession,
  SessionValue,
  clearSession
} from "./functions/Session";
import UserSettings from "./components/UserSettings";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  // global variables
  const {
    active,
    login,
    regist,
    upgradeLogin,
    ErrorType,
    ErrorDesc,
    ErrorShow,
    UpgradePlayers,
    UpgradeSecureCode,
    showUserSettings,
    UpgradeUsID,
    UpgradePlayerStatus
  } = useContext(NavManage);

  // locate the user
  const location = useLocation();
  const { pathname } = location;
  const SiteLocation = pathname.split("/");

  // local variables
  let opened = active ? "menuOpened" : "menuClosed";

  // local functions
  useEffect(() => {
    // login the user if there a session

    if (
      Cookies.get().userID &&
      Cookies.get().gameID &&
      Cookies.get().username &&
      Cookies.get().playerStatus
    ) {
      SetSession("userID", Cookies.get().userID);
      SetSession("username", Cookies.get().username);
      SetSession("gameID", Cookies.get().gameID);
      SetSession("playerStatus", Cookies.get().playerStatus);

      UpgradeUsID(SessionValue("userID"));
      UpgradeSecureCode(SessionValue("gameID"), SessionValue("username"));
      UpgradePlayerStatus(SessionValue("playerStatus"));
      UpgradePlayers();

      // UpgradePlayerStatus("leader");
      upgradeLogin(true);
    } else {
      upgradeLogin(false);
      clearSession();
    }
  }, []);
  return (
    <>
      {ErrorShow === 1 ? <Error type={ErrorType} value={ErrorDesc} /> : ""}

      {login ? (
        <div className="App">
          <Nav />

          <div className="container">
            {SiteLocation[0] === "" && SiteLocation[1] === "" ? (
              <Route path="/" exact>
                <Winner />
              </Route>
            ) : (
              ""
            )}

            {SiteLocation[0] === "" && SiteLocation[1] === "add" ? (
              <Route path="/add" exact>
                <Add />
              </Route>
            ) : (
              ""
            )}

            {SiteLocation[0] === "" &&
            SiteLocation[1] === "game" &&
            SiteLocation[2] === "new" ? (
              <Route path="/game/new" exact>
                <AddGame />
              </Route>
            ) : (
              ""
            )}

            {SiteLocation[0] === "" && SiteLocation[1] === "custom" ? (
              <Route path="/custom/:game" exact>
                <CustomGame />
              </Route>
            ) : (
              ""
            )}

            {SiteLocation[0] === "" &&
            SiteLocation[1] === "custom" &&
            SiteLocation[2] === "log" ? (
              <Route path="/custom/log/:game" exact>
                <CustomGameLog />
              </Route>
            ) : (
              ""
            )}

            {SiteLocation[0] === "" && SiteLocation[1] === "player" ? (
              <Route path="/player/:id" exact>
                {showUserSettings ? <UserSettings /> : <PlayerInfo />}
              </Route>
            ) : (
              ""
            )}

            {SiteLocation[0] === "" && SiteLocation[1] === "wins" ? (
              <Route path="/wins" exact>
                <Winner />
              </Route>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <AnimatePresence>
          {regist ? (
            <motion.div
              key="1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Registration />
            </motion.div>
          ) : (
            <motion.div
              key="2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Login />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

export default App;
